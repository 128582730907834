.filter-buttons {
  padding: 20px 16px;

  &.opened {
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.001);
    padding-right: 15px;
  }
}
.filters-container {
  padding: 7px 16px 0;
  background: rgba(0, 0, 0, 0.04);
  border: 1px solid #f0f0f0;
  border-radius: 0 0 8px 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.001);
  margin-bottom: 10px;
}
.search-hint {
  margin-top: -18px;
}
.btn-container {
  height: 82px;
}

